<template>
  <div>
    <v-dialog content-class="custom-dialog-content" v-model="dialog" @click:outside="closeDialog" max-width="60%">
      <v-card class="mx-auto" outlined>
        <v-card-text class="pa-0 text-end">
          <v-icon class="mt-2 mx-2 primary--text" @click="closeDialog">mdi-close</v-icon>
        </v-card-text>
        <v-card-text class="text-h5 px-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                  <v-stepper-step class="py-3 px-2 text-h6" editable step="1">
                    <v-row dense>
                      <v-col cols="6" dense>
                        <h6 class="primary--text mt-2 font-weight-regular">
                          {{ $t("calendar.calendarEvents") }}
                        </h6>
                      </v-col>
                      <v-col cols="6" class="text-end" dense>
                        <v-btn class="mx-2" color="primary" :disabled="!deleteDisable" small @click="deleteEvent">
                          {{ $t("calendar.delete") }}
                        </v-btn>
                        <v-btn color="primary" small :disabled="!valid ||
                          deleteDisable ||
                          (accessRight.length < 2 &&
                            accessRight.includes('show'))
                          " :loading="loading" @click="saveCalendarEvent">
                          {{ $t("contact.save") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-step>
                  <v-stepper-content step="1" class="ml-5 mb-auto pb-0 mr-3 py-2 px-2">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field class="asterisk" hide-details="auto" v-model="model.title"
                          :label="$t('calendar.title')" :rules="titleValid" :readonly="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            " required />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field class="asterisk" hide-details="auto" v-model="model.details"
                          :label="$t('calendar.details')" :rules="detialsRequired" :readonly="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            " required />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <label style="font-size: 60%" class="caption" v-text="$t('calendar.startTime')" />
                        <vue-timepicker class="asterisk" :disabled="deleteDisable ||
                          (accessRight.length < 2 &&
                            accessRight.includes('show'))
                          " :placeholder="$t('delivery.field.hour')" v-model="model.start_time" enableRtl="false">
                          <template #clearButton>
                            <v-icon :class="locale === 'he' ? 'hebrewposition' : ''">mdi-close</v-icon>
                          </template>
                        </vue-timepicker>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <label style="font-size: 60%" class="caption" v-text="$t('calendar.endTime')" />
                        <vue-timepicker class="asterisk" :disabled="deleteDisable ||
                          (accessRight.length < 2 &&
                            accessRight.includes('show'))
                          " :placeholder="$t('delivery.field.hour')" v-model="model.end_time" enableRtl="false">
                          <template #clearButton>
                            <v-icon :class="locale === 'he' ? 'hebrewposition' : ''">mdi-close</v-icon>
                          </template>
                        </vue-timepicker>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                          :return-value.sync="model.start_date" transition="scale-transition" offset-y min-width="auto"
                          :disabled="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            ">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field class="asterisk" hide-details="auto" v-model="formattedStartDate"
                              :label="$t('calendar.startDate')" v-bind="attrs" :rules="profileById.date_format ? dateValid : dateValid2
                                " :readonly="deleteDisable ||
                                  (accessRight.length < 2 &&
                                    accessRight.includes('show'))
                                  " v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="model.start_date"
                            @change="$refs.startDateMenu.save(model.start_date)">
                            <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{
                              $t("route.today") }}</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false"
                          :return-value.sync="model.end_date" transition="scale-transition" offset-y min-width="auto"
                          :disabled="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            ">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field class="asterisk" hide-details="auto" v-model="formattedEndDate"
                              :label="$t('calendar.endDate')" v-bind="attrs" :rules="profileById.date_format ? dateValid : dateValid2
                                " :readonly="deleteDisable ||
                                  (accessRight.length < 2 &&
                                    accessRight.includes('show'))
                                  " v-on="on"></v-text-field>
                          </template>
                          <v-date-picker :min="model.start_date ? model.start_date : ''" v-model="model.end_date"
                            @change="$refs.endDateMenu.save(model.end_date)">
                            <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{
                              $t("route.today") }}</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-combobox v-model="selectedEmployee" class="asterisk" autocomplete="new-password"
                          :items="employeeList.data" item-text="name" item-value="id" @change="onEmployeeChange" flat
                          return-object hide-no-data hide-details="auto" required :rules="employeeNameRequired"
                          :search-input.sync="employeeSearch" :label="$t('calendar.employee')" multiple :readonly="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            ">
                          <template v-slot:item="{ item }">
                            <div class="font-weight-medium d-flex align-center" style="font-size: 0.8125rem">
                              <v-checkbox dense class="mb-n1" v-model="selectedEmployeesMap[item.id]"></v-checkbox>
                              {{ `${item.id}. ${item.name}` }}
                            </div>
                          </template>
                          <template v-slot:append-item>
                            <div v-show="hasEmployeeNextPage" v-intersect="infiniteScrollOnEmployee" ref="load"
                              class="loader text-center">
                              <v-progress-circular v-if="
                                employeeList.data.length <
                                employeeList.meta.total
                              " indeterminate color="primary" />
                            </div>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete class="my-2 asterisk" hide-details="auto" v-model="model.priority"
                          :items="priorityList" :rules="priorityRequired" item-text="name" item-value="slug" flat
                          return-object single-line :label="$t('calendar.priority')" :readonly="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            ">
                          <template v-slot:item="{ item }">
                            {{ item.name }}
                            <v-icon class="mx-3" :color="item.color">mdi-circle</v-icon>
                          </template>
                          <template v-slot:selection="{ item }">
                            {{ item.name }}
                            <v-icon class="mx-3" :color="item.color">mdi-circle</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete class="my-2 asterisk" hide-details="auto" dense v-model="model.event_type"
                          :items="typeValue" :rules="typeValid" item-text="description" item-value="id" flat
                          return-object single-line :label="$t('calendar.eventType')" :readonly="deleteDisable ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                            " />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete hide-details="auto" dense v-model="selectedStatusOfCalendar" :items="statusList"
                          item-text="name" item-value="id" flat return-object single-line
                          :label="$t('quotation.field.status')" @change="changeCalendarStatus" />
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <RefreshToken :dialog.sync="refreshTokenDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Alert";
import {
  getAuxiliaryZtableValueByName,
  formatDateDDMMYYYY,
  todayDate,
  parseDateYYYYMMDD,
} from "../utils";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import RefreshToken from "./RefreshToken";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "CalendarForm",
  props: ["dialog", "docId", "docType", "modelData", "getByRes"],
  components: {
    Alert,
    VueTimepicker,
    RefreshToken,
  },
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      e6: 1,
      alert: false,
      message: "",
      employeeSearch: "",
      selectedEmployee: [],
      selectedEmployeesMap: {},
      employeeLimit: 10,
      model: {
        status: null,
        start_date: new Date().toISOString().substring(0, 10),
        end_date: new Date().toISOString().substring(0, 10),
        start_time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        end_time: new Date(
          new Date().getTime() + 30 * 60000
        ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      },
      startDateMenu: false,
      endDateMenu: false,
      typeValue: [],
      selectedStatus: null,
      loading: false,
      refreshTokenDialog: false,
      selectedStatusOfCalendar: { id: 0 },
    };
  },
  async mounted() {
    if (this.dialog) {
      if (
        this.docType === "Quotation" ||
        this.docType === "Order" ||
        this.docType === "ProformaInvoice" ||
        this.docType === "Delivery" ||
        this.docType === "Invoice" ||
        this.docType === "CreditInvoice" ||
        this.docType === "ReceiptTaxInvoice" ||
        this.docType === "ReturnCertificate" ||
        this.docType === "Receipt"
      ) {
        this.model.title =
          this.docType +
          "-" +
          this.docId +
          "-" +
          this.modelData?.customer_name +
          "-" +
          this.modelData?.customer_id +
          (this.modelData?.title ? "-" + this.modelData?.title : "");
      } else if (
        this.docType === "PurchaseOrder" ||
        this.docType === "SupplierDeliveryNote" ||
        this.docType === "SupplierInvoice" ||
        this.docType === "SupplierCreditInvoice" ||
        this.docType === "SupplierPayment"
      ) {
        this.model.title =
          this.docType +
          "-" +
          this.docId +
          "-" +
          this.modelData?.supplier_name +
          "-" +
          this.modelData?.supplier_id +
          (this.modelData?.title ? "-" + this.modelData?.title : "");
      }
      this.model.details =
        (this.modelData.address ? this.modelData.address + "-" : "") +
        (this.modelData.email ? this.modelData.email : "") +
        (this.modelData.phone_1 ? "-" + this.modelData.phone_1 : "") +
        (this.modelData.phone_2 ? "-" + this.modelData.phone_2 : "") +
        (this.modelData.remarks ? "-" + this.modelData.remarks : "");
    }

    if (this.getByRes && this.getByRes?.id) {
      this.model = this.getByRes;
      this.selectedStatus = this.getByRes.status;
      this.selectedStatusOfCalendar = this.getByRes.status_one;
      this.selectedEmployee = this.getByRes.employee_id.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      this.selectedEmployee.forEach((employee) => {
        this.$set(this.selectedEmployeesMap, employee.id, true);
      });
    }

    let titleTableNameValue = await getAuxiliaryZtableValueByName(22);
    if (Object.keys(titleTableNameValue).length > 0) {
      this.typeValue = titleTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
  watch: {
    employeeSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: 10,
          where_like: newVal ? "id,name|" + newVal : newVal,
          order_by: "name|asc",
        });
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    "model.start_date"(val) {
      this.formattedStartDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;
      // If the end date is not set or is before the start date, set it to the start date
      if (!this.model.end_date || this.model.end_date < val) {
        this.model.end_date = val;
      }
    },
    "model.end_date"(val) {
      this.formattedEndDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;

      // If the start date is after the end date, set it to the end date
      if (this.model.start_date > val) {
        this.model.start_date = val;
      }
    },
    "model.start_time"(newVal, oldVal) {
      if (newVal !== oldVal) {
        let [hours, minutes] = newVal.split(":").map(Number);
        let date = new Date();
        date.setHours(hours, minutes);
        date.setMinutes(date.getMinutes() + 30);

        let endHours = date.getHours().toString().padStart(2, "0");
        let endMinutes = date.getMinutes().toString().padStart(2, "0");
        this.model.end_time = `${endHours}:${endMinutes}`;
      }
    },
    "model.end_time"(val) {
      if (val) {
        if (this.model.start_date && this.model.end_date && this.model.start_date === this.model.end_date) {
          if (this.model.start_time > val) {
            this.$nextTick(() => {
              this.model.end_time = this.model.start_time;
              this.message = "Start time cannot be greater than end time on the same day";
              this.alert = true;
          });
          }
        }
      }
    },
    profileById(val) {
      this.model.start_date = val.date_format
        ? formatDateDDMMYYYY(this.model.end_date)
        : this.model.end_date;
      this.model.end_date = val.date_format
        ? formatDateDDMMYYYY(this.model.start_date)
        : this.model.start_date;
    },
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      profileById: "profile/profileById",
      employeeList: "employee/employeeList",
      locale: "locale",
      user: "user",
    }),
    formattedStartDate: {
      get() {
        return this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.start_date)
          : parseDateYYYYMMDD(this.model.start_date);
      },
      set(val) {
        this.model.start_date = this.profileById.date_format
          ? this.parseDateYYYYMMDD(val)
          : formatDateDDMMYYYY(val);
      },
    },
    formattedEndDate: {
      get() {
        return this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.end_date)
          : parseDateYYYYMMDD(this.model.end_date);
      },
      set(val) {
        this.model.end_date = this.profileById.date_format
          ? this.parseDateYYYYMMDD(val)
          : formatDateDDMMYYYY(val);
      },
    },
    priorityList() {
      return [
        {
          id: null,
          name: this.$t("calendar.high"),
          color: "#D50700",
          slug: "high",
        },
        {
          id: 1,
          name: this.$t("calendar.medium"),
          color: "#049BE5",
          slug: "medium",
        },
        { id: 2, name: this.$t("calendar.low"), color: "#0C8043", slug: "low" },
      ];
    },
    statusList() {
      return [
        { name: this.$t("quotation.field.open"), id: 0 },
        { name: this.$t("quotation.field.finished"), id: 1 },
        { name: this.$t("quotation.field.canceled"), id: 2 },
      ];
    },
    priorityRequired() {
      return [(v) => !!v || this.$t("formRules.priorityRequired")];
    },
    typeValid() {
      return [(v) => !!v || this.$t("formRules.eventTypeRequired")];
    },
    addressRequired() {
      return [
        (v) => !!v || this.$t("formRules.addressRequired"),
        (v) =>
          !this.profileById.title ||
          !!v ||
          this.$t("formRules.addressRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.addressRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("calendar.address"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    detialsRequired() {
      return [
        (v) => !!v || this.$t("formRules.detialsRequired"),
        (v) =>
          !this.profileById.title ||
          !!v ||
          this.$t("formRules.detialsRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.detialsRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("calendar.details"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    employeeNameRequired() {
      return [
        (v) => !!v || this.$t("formRules.employeeRequired"),
        (v) =>
          !this.selectedEmployee ||
          !!v ||
          this.$t("formRules.employeeRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.employeeRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("calendar.details"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    deleteDisable() {
      return this.getByRes && this.getByRes.id ? true : false;
    },
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    hasEmployeeNextPage() {
      if (this.employeeList.data) {
        return this.employeeList.data.length < this.employeeList.meta.total;
      } else {
        return false;
      }
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        const selectedEmployeeIds = Object.keys(
          this.selectedEmployeesMap
        ).filter((id) => this.selectedEmployeesMap[id]);
        const selectedEmployeesMultiple = this.employeeList.data.filter(
          (item) => selectedEmployeeIds.includes(item.id.toString())
        );
        this.selectedEmployee = selectedEmployeesMultiple.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        this.model.employee_id = this.selectedEmployee;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async changeCalendarStatus() {
      if (this.getByRes && this.getByRes.id) {
        await this.$store.dispatch("calendar/EditCalendar", {
          event_id: this.getByRes.id,
          status_one: this.selectedStatusOfCalendar.id,
        });
        this.$emit("update:dialog", false);
      }
    },
    async closeDialog() {
      this.$emit("update:dialog", false);
      this.$refs.form.reset();
    },
    async deleteEvent() {
      if (this.getByRes?.id) {
        await this.$store.dispatch(
          "calendar/DeleteCalendarById",
          this.getByRes?.id
        );
        this.model.start_time = "HH:mm";
        this.model.end_time = "HH:mm";
        this.$refs.form.reset();
        this.$emit("update:dialog", false);
      }
    },
    async saveCalendarEvent() {

      // Check if the start date is greater than the end date
      if (this.model.start_date > this.model.end_date) {
        this.message = "calendar.error.dateIssue";
        this.alert = true;
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.profileById.calander_type === "google") {
          await this.$store
            .dispatch("calendar/RefreshTokenCheck", `?user_id=${this.user.id}`)
            .then((res) => {
              if (res.isToken == true) {
                if (!this.model.start_time) {
                  this.model.start_time = "12:00";
                } else {
                  if (
                    this.model.start_time == null ||
                    this.model.start_time == undefined ||
                    this.model.start_time == ""
                  ) {
                    this.model.start_time = "12:00";
                  } else {
                    if (!this.model.start_time.HH && this.model.start_time.mm) {
                      this.model.start_time = "12:" + this.model.start_time.mm;
                    } else if (
                      !this.model.start_time.mm &&
                      this.model.start_time.HH
                    ) {
                      this.model.start_time = this.model.start_time.HH + ":00";
                    } else if (
                      this.model.start_time.mm &&
                      this.model.start_time.HH
                    ) {
                      this.model.start_time =
                        this.model.start_time.HH +
                        ":" +
                        this.model.start_time.mm;
                    } else {
                      this.model.start_time = this.model.start_time.replace(
                        "mm",
                        "00"
                      );
                      this.model.start_time = this.model.start_time.replace(
                        "HH",
                        "12"
                      );
                    }
                  }
                }

                if (!this.model.end_time) {
                  this.model.end_time = "12:00";
                } else {
                  if (
                    this.model.end_time == null ||
                    this.model.end_time == undefined ||
                    this.model.end_time == ""
                  ) {
                    this.model.end_time = "12:00";
                  } else {
                    if (!this.model.end_time.HH && this.model.end_time.mm) {
                      this.model.end_time = "12:" + this.model.end_time.mm;
                    } else if (
                      !this.model.end_time.mm &&
                      this.model.end_time.HH
                    ) {
                      this.model.end_time = this.model.end_time.HH + ":00";
                    } else if (
                      this.model.end_time.mm &&
                      this.model.end_time.HH
                    ) {
                      this.model.end_time =
                        this.model.end_time.HH + ":" + this.model.end_time.mm;
                    } else {
                      this.model.end_time = this.model.end_time.replace(
                        "mm",
                        "00"
                      );
                      this.model.end_time = this.model.end_time.replace(
                        "HH",
                        "12"
                      );
                    }
                  }
                }

                if (
                  this.selectedStatus &&
                  Object.keys(this.selectedStatus).length > 0
                ) {
                  this.model.status = this.selectedStatus.id;
                } else {
                  this.model.status = this.selectedStatus;
                }

                if (
                  this.selectedStatusOfCalendar &&
                  Object.keys(this.selectedStatusOfCalendar).length > 0
                ) {
                  this.model.status_one = this.selectedStatusOfCalendar.id;
                } else {
                  this.model.status_one = this.selectedStatusOfCalendar;
                }

                let extendedModel = {
                  ...this.model,
                  details:
                    this.model.details +
                    "-" +
                    this.model.event_type.description,
                  event_type: this.model.event_type.id,
                  priority: this.model.priority?.slug,
                  document_id: this.docId,
                  document_type: this.docType,
                  type: this.profileById.calander_type,
                  user_id: this.user.id,
                };
                delete extendedModel.status;
                this.$store.dispatch("calendar/SetCalendar", extendedModel);
                this.loading = false;
                this.$emit("update:dialog", false);
              } else {
                this.refreshTokenDialog = true;
                this.loading = false;
              }
            });
        } else {
          await this.$store
            .dispatch(
              "calendar/OutlookRefreshTokenCheck",
              `?user_id=${this.user.id}`
            )
            .then((res) => {
              if (res.isToken == true) {
                if (!this.model.start_time) {
                  this.model.start_time = "12:00";
                } else {
                  if (
                    this.model.start_time == null ||
                    this.model.start_time == undefined ||
                    this.model.start_time == ""
                  ) {
                    this.model.start_time = "12:00";
                  } else {
                    if (!this.model.start_time.HH && this.model.start_time.mm) {
                      this.model.start_time = "12:" + this.model.start_time.mm;
                    } else if (
                      !this.model.start_time.mm &&
                      this.model.start_time.HH
                    ) {
                      this.model.start_time = this.model.start_time.HH + ":00";
                    } else if (
                      this.model.start_time.mm &&
                      this.model.start_time.HH
                    ) {
                      this.model.start_time =
                        this.model.start_time.HH +
                        ":" +
                        this.model.start_time.mm;
                    } else {
                      this.model.start_time = this.model.start_time.replace(
                        "mm",
                        "00"
                      );
                      this.model.start_time = this.model.start_time.replace(
                        "HH",
                        "12"
                      );
                    }
                  }
                }

                if (!this.model.end_time) {
                  this.model.end_time = "12:00";
                } else {
                  if (
                    this.model.end_time == null ||
                    this.model.end_time == undefined ||
                    this.model.end_time == ""
                  ) {
                    this.model.end_time = "12:00";
                  } else {
                    if (!this.model.end_time.HH && this.model.end_time.mm) {
                      this.model.end_time = "12:" + this.model.end_time.mm;
                    } else if (
                      !this.model.end_time.mm &&
                      this.model.end_time.HH
                    ) {
                      this.model.end_time = this.model.end_time.HH + ":00";
                    } else if (
                      this.model.end_time.mm &&
                      this.model.end_time.HH
                    ) {
                      this.model.end_time =
                        this.model.end_time.HH + ":" + this.model.end_time.mm;
                    } else {
                      this.model.end_time = this.model.end_time.replace(
                        "mm",
                        "00"
                      );
                      this.model.end_time = this.model.end_time.replace(
                        "HH",
                        "12"
                      );
                    }
                  }
                }

                if (
                  this.selectedStatus &&
                  Object.keys(this.selectedStatus).length > 0
                ) {
                  this.model.status = this.selectedStatus.id;
                } else {
                  this.model.status = this.selectedStatus;
                }

                let extendedModel = {
                  ...this.model,
                  details:
                    this.model.details +
                    "-" +
                    this.model.event_type.description,
                  event_type: this.model.event_type.id,
                  priority: this.model.priority?.slug,
                  document_id: this.docId,
                  document_type: this.docType,
                  type: this.profileById.calander_type,
                  user_id: this.user.id,
                };
                delete extendedModel.status;
                this.$store.dispatch("calendar/SetCalendar", extendedModel);
                this.loading = false;
                this.$emit("update:dialog", false);
              } else {
                this.refreshTokenDialog = true;
                this.loading = false;
              }
            });
        }
      } else {
        this.valid = false;
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>

<style scoped>
.hebrewposition {
  position: absolute;
  right: 112px;
}

::v-deep .vue__time-picker input.display-time {
  height: 1.2em;
  width: 5em;
}

::v-deep .vue__time-picker .dropdown {
  width: 5em;
  height: 6em;
}

::v-deep .vue__time-picker .dropdown .select-list {
  width: 5em;
  height: 6em;
}

::v-deep .vue__time-picker .dropdown ul li {
  font-size: 15px;
}

::v-deep .vue__time-picker input.display-time {
  font-size: 20px;
}
</style>
